import Home from "./pages/Home";
import AOS from 'aos';
import { useEffect } from "react";
import 'aos/dist/aos.css'; 


function App() {

  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  return (
    <>
    <Home />
    </>
  )
}

export default App;
