import React from 'react'

function UnderConstruction() {
  return (
    <div className='bgurl h-screen'>
      <div className='flex flex-col justify-center w-full h-full items-center bgrgb'>
        <h1 className='text-3xl lg:h-16 font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-green-600'>
          Projeto Em Reforma
        </h1>
        <div class="flex flex-wrap justify-center gap-4 mt-8">
          <a class="block w-full px-12 py-3 text-sm font-medium text-white bg-blue-600 border border-blue-600 rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-white focus:outline-none focus:ring" href='https://www.homolog.victordeveloper.com.br'>
            Ver Projeto em Homologação
          </a>
        </div>
      </div>
    </div>
  )
}

export default UnderConstruction