import React from 'react'
// import Banner from '../components/Banner'
// import Contact from '../components/Contact'
// import Footer from '../components/Footer'
// import Header from '../components/Header'
// import Projects from '../components/Projects'
import UnderConstruction from '../components/UnderConstruction'

function Home() {
  return (
    <>
      {/* <Header /> */}
      {/* <Banner />
      <Projects />
      <Contact />
      <Footer /> */}
      <UnderConstruction />
    </>
  )
}

export default Home